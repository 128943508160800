import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from '@charlycares/shared'
import { LandingLayout, TextInput } from '@charlycares/ui'
import { Button, Flex, Heading, HStack, Text } from 'native-base'
import { useGiveReviewMutation } from '@charlycares/data-access'

import { useAlert } from '../../../hooks'

const BookingSendFeedbackScreen = () => {
  const { t } = useTranslation()
  const { navigate, getParam } = useRouter()
  const alert = useAlert()
  const [showAlert, setShowAlert] = useState(true)

  const [comment, setComment] = useState('')

  const [giveRating, { isLoading }] = useGiveReviewMutation()

  const isBookedBefore = getParam('isBookedBefore', false) as boolean
  const booking_id = getParam('bookingId', []) as string
  const ratings = getParam('ratings', []) as {
    label: string
    value: number
    key: string
  }[]
  const paymentMethod = getParam('paymentMethod') as string

  const averageRating = ratings.reduce((prev, current) => prev + current.value, 0) / ratings.length

  const onSubmit = async () => {
    try {
      const categories: {
        [key: string]: number
      } = {}

      ratings.forEach(rating => {
        categories[rating.key] = rating.value
      })

      await giveRating({ ratings: categories, comment, booking_id }).unwrap()

      navigate('BookingFinished', '/booking-finished', { paymentMethod, bookingId: booking_id })
    } catch (error) {
      alert.show(t('error'), (error as any)?.data?.message || t('postReviewError'))
    }
  }

  const onLater = () => {
    if (showAlert) {
      if (!isBookedBefore || averageRating < 4) {
        alert.show(t('reviewIsRequiredAlert'), t(isBookedBefore ? 'reviewRequiredBelow4' : 'reviewRequiredFirstTime'), [
          { text: t('understand') }
        ])
      }

      setShowAlert(false)
      return
    }

    navigate('BookingFinished', '/booking-finished', { paymentMethod, bookingId: booking_id })
  }

  return (
    <LandingLayout
      BottomComponent={
        <HStack space={'5px'} px="20px">
          <Button
            onPress={onLater}
            variant="unstyled"
            _text={{ color: 'white' }}
            borderWidth="1px"
            borderColor="white"
            flex={1}
            rounded="full"
          >
            {t('later')}
          </Button>
          <Button
            isLoading={isLoading}
            isDisabled={comment.trim().length < 5}
            onPress={onSubmit}
            width="200px"
            ml="10px"
            bgColor="white"
            _text={{ color: 'secondary.400' }}
          >
            {t('giveFeedback')}
          </Button>
        </HStack>
      }
    >
      <Flex justifyContent="center" height="full" px="20px" mt="40px">
        <Heading fontSize={22} color="white">
          {averageRating > 3.9 ? t('writeReviewTitle_above_3.9') : t('writeReviewTitle_below_3.9')}
        </Heading>
        <Text mt="10px" mb="30px" fontSize={15} color="white">
          {averageRating > 3.9 ? t('writeReviewDesc_above_3.9') : t('writeReviewDesc_below_3.9')}
        </Text>

        <TextInput
          rounded="md"
          placeholder={averageRating > 3.9 ? t('giveComplimentPlaceholder') : t('reviewTextPlaceholder')}
          maxLength={500}
          mt="5px"
          h="110px"
          variant="filled"
          multiline
          numberOfLines={5}
          value={comment}
          onChangeText={setComment}
        />
      </Flex>
    </LandingLayout>
  )
}

export default BookingSendFeedbackScreen

BookingSendFeedbackScreen.navigationOptions = {
  headerShown: false
}
