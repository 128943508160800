import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CharlyIcon, Picker, ScreenLayout } from '@charlycares/ui'
import { Badge, Box, Button, Center, Heading, HStack, Skeleton, Spinner, Text, useTheme } from 'native-base'
import {
  GetJobsError,
  setJobboardSort,
  useAppSelector,
  useGetJobsQuery,
  useGetUserIndicatorsQuery,
  useGetUserQuery
} from '@charlycares/data-access'
import { contactPaginatedData, IJobPost, JobboardSortTypes, useRouter } from '@charlycares/shared'
import { useDispatch } from 'react-redux'
import { FlatList } from 'react-native'

import { AngelTodoList } from '../profile'
import { AngelAppointment } from '../onboarding'
import { JobCard, useLoadJobboardFilters } from './common'
import { withNavigationFocus } from 'react-navigation'
import FavoriteAngelsScreen from '../favourites/FavoriteAngelsScreen'

const sortItems = [
  {
    column: JobboardSortTypes.BOOKING_DATE,
    label: 'jobboardSortDate'
  },
  {
    column: JobboardSortTypes.DISTANCE,
    label: 'jobboardSortDistance'
  },
  {
    column: JobboardSortTypes.HOURLY_RATE,
    label: 'jobboardSortMaxRate'
  }
]

const JobboardScreen = ({ isFocused }: { isFocused: boolean }) => {
  const { t } = useTranslation()
  const { colors } = useTheme()
  const { navigate } = useRouter()
  const dispatch = useDispatch()

  const sortPickerRef = useRef<any>()

  const [jobs, setJobs] = useState<IJobPost[]>([])
  const [errorMessage, setErrorMessage] = useState<{ title: string; message: string }>()
  const [page, setPage] = useState(1)

  const sorts = useAppSelector(state => state.jobboard.sorts)
  const filters = useAppSelector(state => state.jobboard.filters)
  const filtersCount = useAppSelector(state => state.jobboard.filtersCount)

  const { data: user } = useGetUserQuery()
  const { isLoading: loadingFilters } = useLoadJobboardFilters()
  const {
    data: result,
    isFetching,
    isSuccess,
    isLoading,
    isError,
    error,
    refetch
  } = useGetJobsQuery(
    {
      ...filters,
      sorts,
      page
    },
    { skip: loadingFilters }
  )
  const { refetch: refetchIndicators } = useGetUserIndicatorsQuery()

  useEffect(() => {
    if (isSuccess && !isFetching && result) {
      setJobs(state => contactPaginatedData(state, result))
    }
  }, [result, isFetching, isSuccess])

  useEffect(() => {
    if (isError) {
      const err = error as GetJobsError
      if (err.status === 403 && err.data.error) {
        // user error
        setErrorMessage({
          title: t(`jobboardErrorTitle_${err.data.error}`),
          message: t(`jobboardErrorMessage_${err.data.error}`)
        })
      } else {
        // validation or server error
        setErrorMessage({
          title: t('jobboardValidationErrorTitle'),
          message: err.data.message || t('jobboardValidationErrorMessage')
        })
      }
    } else {
      setErrorMessage(undefined)
    }
  }, [isError, error, t])

  useEffect(() => {
    if (isFocused && !isLoading) {
      refetchIndicators()
    }
  }, [isFocused, isLoading])

  const renderMessage = (title: string, message: string) => {
    return (
      <Card borderWidth={1} mt="0" mb="10px">
        <Center w="100%">
          <Heading fontWeight="600" fontSize="15px" textAlign="center">
            {title}
          </Heading>
          <Text fontSize="14px" mt="7px" textAlign="center">
            {message}
          </Text>
        </Center>
      </Card>
    )
  }

  //TODO: Legacy picker should be refactored
  const currentSort = sortItems.find(item => item.column === sorts[0].column)?.label || ''
  const onSortChange = (label: string) => {
    const sortColumn = sortItems.find(item => t(item.label) === t(label))?.column
    if (sortColumn) {
      dispatch(setJobboardSort(sortColumn))
    }
  }

  return (
    <ScreenLayout edges={['left', 'right']} disableScroll hideBackButton supportButton title={t('jobboard')}>
      <Box zIndex={2} flexDir="row" py="13px" bg="gray.50" borderBottomWidth={0.5} borderColor="gray.200">
        <Center flex={1}>
          <Button
            onPress={() => navigate('JobBoardFilter')}
            startIcon={<CharlyIcon size={24} name="icn-filter" color={colors.gray[800]} />}
            endIcon={filtersCount ? <Badge>{filtersCount}</Badge> : undefined}
            _text={{ fontSize: '13px', fontWeight: '600', color: 'gray.800' }}
            variant="text"
          >
            {t('filters')}
          </Button>
        </Center>

        <Center flex={1}>
          <Button
            startIcon={<CharlyIcon size={24} name="sort" color={colors.gray[800]} />}
            _text={{ fontSize: '13px', fontWeight: '600', color: 'gray.800' }}
            variant="text"
            onPress={() => sortPickerRef.current?.open()}
          >
            {t(currentSort) || t('sort')}
          </Button>
        </Center>
      </Box>

      {!isLoading && !loadingFilters && (
        <FlatList
          data={jobs}
          keyExtractor={item => item.obscured_id}
          refreshing={isFetching}
          onRefresh={() => {
            setPage(1)
            refetch()
          }}
          onEndReachedThreshold={0.9}
          onEndReached={() => {
            if (result?.meta.last_page && page < result?.meta.last_page && !isFetching) {
              if (result?.meta.current_page === page) {
                setPage(page + 1)
              }
            }
          }}
          ListHeaderComponent={
            user?.angel?.has_todo_items
              ? () => (
                  <Box w="100%">
                    {error && errorMessage ? renderMessage(errorMessage.title, errorMessage.message) : null}
                    {!user?.angel?.screening_completed && <AngelAppointment />}
                    <AngelTodoList hideTitle px="20px" />
                  </Box>
                )
              : undefined
          }
          renderItem={({ item }) => (
            <JobCard
              onPress={() =>
                navigate('JobFinderDetails', '/job-post-details', {
                  obscuredId: item.obscured_id
                })
              }
              mb="5px"
              data={item}
            />
          )}
          ListEmptyComponent={() =>
            isSuccess && jobs.length === 0 ? renderMessage(t('emptyJobboardTitle'), t('emptyJobboardMessage')) : null
          }
          ListFooterComponent={() =>
            (page !== 1 && page !== result?.meta.last_page) || isFetching ? <Spinner mt="10px" /> : null
          }
        />
      )}

      {(isLoading || loadingFilters) &&
        Array.from({ length: 4 }).map((_, index) => (
          <HStack
            key={index}
            mb="5px"
            w="100%"
            borderTopWidth={1}
            borderBottomWidth={1}
            borderColor="gray.200"
            p="20px"
          >
            <Box flex={2}>
              <HStack space="10px" alignItems="center">
                <Skeleton size="50px" rounded="full" startColor="gray.400" />
                <Skeleton h="3" w="150px" mt="10px" rounded="full" startColor="gray.400" />
              </HStack>
              <Skeleton h="3" w="150px" mt="10px" rounded="full" startColor="gray.400" />
              <Skeleton h="3" w="100px" mt="10px" rounded="full" startColor="gray.400" />
            </Box>

            <Center flex={1} justifyContent="center">
              <Skeleton h="3" w="60%" rounded="full" startColor="gray.400" />
              <Skeleton mt="10px" h="30px" w="61px" rounded="full" startColor="gray.400" />
            </Center>
          </HStack>
        ))}

      <Picker
        ref={sortPickerRef}
        options={sortItems.map(item => t(item.label))}
        value={t(currentSort)}
        onConfirmPress={onSortChange}
      />
    </ScreenLayout>
  )
}

const screen = withNavigationFocus(JobboardScreen)
export default screen

// @ts-ignore
screen.navigationOptions = {
  headerShown: false
}
